define('movie-rating-ember/routes/application', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Route.extend({
    title: function title(tokens) {
      return 'CineR8 - ' + tokens.join(' - ');
    },


    session: _ember.default.inject.service(),

    beforeModel: function beforeModel() {
      return this.get('session').fetch().catch(function () {});
    },
    model: function model() {
      if (this.get('session.isAuthenticated')) {
        return this.store.findRecord('user', this.get('session.currentUser.uid'));
      }
    },


    actions: {
      signOut: function signOut() {
        this.get('session').close();
      },
      refreshModel: function refreshModel() {
        if (this.get('session.isAuthenticated')) {
          this.refresh();
        }
      }
    }
  });
});