define('movie-rating-ember/serializers/user', ['exports', 'emberfire/serializers/firebase'], function (exports, _firebase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _firebase.default.extend({
    attrs: {
      ratings: { embedded: 'always' },
      wannaSees: { embedded: 'always' }
    }
  });
});