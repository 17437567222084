define('movie-rating-ember/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    ratings: _emberData.default.hasMany('rating', { async: false, inverse: null }),
    wannaSees: _emberData.default.hasMany('wannaSee', { async: false, inverse: null })
  });
});