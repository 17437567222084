define("movie-rating-ember/controllers/application", ["exports", "ember"], function (exports, _ember) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Controller.extend({
        search: "",
        actions: {
            querySearch: function querySearch() {
                var value = document.getElementById("searchInput").value;
                document.getElementById("searchInput").value = "";
                this.transitionToRoute('search', { queryParams: { value: value } });
            }
        },
        observeSession: _ember.default.observer("session.isAuthenticated", function () {
            this.send("refreshModel");
        })
    });
});