define('movie-rating-ember/routes/movie', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Route.extend({
        titleToken: function titleToken(model) {
            return model.movie.title;
        },
        model: function model(_ref) {
            var movie_id = _ref.movie_id;

            var userId = this.get('session.currentUser.uid');
            var movie = _ember.default.$.getJSON('https://api.themoviedb.org/3/movie/' + movie_id + '?api_key=0910db5745f86638474ffefa5d3ba687&append_to_response=videos,credits');
            var userRating = this.get('firebaseUtil').findRecord(movie_id, 'users/' + userId + '/ratings/' + movie_id).catch(function (error) {
                //error
                alert(error);
            });
            var wannaSee = this.get('firebaseUtil').isRecordExisting('users/' + userId + '/wannaSees/' + movie_id);
            return _ember.default.RSVP.hash({
                movie: movie,
                userRating: userRating,
                wannaSee: wannaSee
            });
        },
        afterModel: function afterModel(model) {
            this._super(model);
            _ember.default.RSVP.hash({
                tmp: _ember.default.$.getJSON("https://omdbapi.com/?apikey=8084da1&i=" + model.movie.imdb_id)
            }).then(function (hash) {
                _ember.default.set(model, 'movieOMDb', hash.tmp);
            });
        }
    });
});