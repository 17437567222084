define('movie-rating-ember/models/rating', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        movieTitle: _emberData.default.attr('string'),
        value: _emberData.default.attr('number'),
        posterPath: _emberData.default.attr('string')
    });
});