define('movie-rating-ember/controllers/login', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Controller.extend({
        actions: {
            loginWithGoogle: function loginWithGoogle() {
                var self = this;
                this.get('session').open('firebase', {
                    provider: 'google'
                }).then(function () {
                    var id = self.get('session.currentUser.uid');
                    var email = self.get('session.currentUser.email');

                    self.get('firebaseUtil').isRecordExisting('users/' + id).then(function (result) {
                        if (!result) {
                            var newUser = self.store.createRecord('user', {
                                id: id,
                                email: email,
                                username: email.split('@')[0]
                            });
                            newUser.save();
                        }
                    });
                    self.transitionToRoute('application');
                }).catch(function (error) {
                    alert(error);
                });
            },
            loginWithEmail: function loginWithEmail() {
                var email = document.getElementById('email').value;
                var pass = document.getElementById('password').value;
                this.get('session').open('firebase', {
                    provider: 'password',
                    email: email,
                    password: pass
                }).then(function () {
                    self.transitionToRoute('application');
                }, function () {
                    alert("Failure");
                });
            }
        }
    });
});