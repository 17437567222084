define('movie-rating-ember/components/head-content', ['exports', 'ember', 'movie-rating-ember/templates/head'], function (exports, _ember, _head) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    tagName: '',
    model: _ember.default.inject.service('head-data'),
    layout: _head.default
  });
});