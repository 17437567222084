define('movie-rating-ember/routes/user', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Route.extend({
        model: function model(params) {
            return this.store.query('user', {
                orderBy: 'username',
                equalTo: params.username
            }).then(function (model) {
                return model.get('firstObject');
            });
        },
        titleToken: function titleToken(model) {
            return 'User - ' + model.get('username');
        }
    });
});