define('movie-rating-ember/routes/index', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Route.extend({
        titleToken: 'Movie Rating',
        model: function model() {
            var popularNow = _ember.default.$.getJSON("https://api.themoviedb.org/3/discover/movie?api_key=0910db5745f86638474ffefa5d3ba687&sort_by=popularity.desc");
            var highestRated = _ember.default.$.getJSON("https://api.themoviedb.org/3/discover/movie?api_key=0910db5745f86638474ffefa5d3ba687&sort_by=vote_average.desc&vote_count.gte=2000");

            return _ember.default.RSVP.hash({
                popularNow: popularNow,
                highestRated: highestRated
            });
        }
    });
});