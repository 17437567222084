define('movie-rating-ember/controllers/movie', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Controller.extend({
        actions: {
            updateRating: function updateRating(_ref) {
                var model = _ref.item,
                    rating = _ref.rating;

                var fanoutObject = {};
                var userId = this.get('session.currentUser.uid');

                this.send('dontWannaSee', model);

                fanoutObject['users/' + userId + '/ratings/' + model.movie.id + '/value'] = rating;
                fanoutObject['users/' + userId + '/ratings/' + model.movie.id + '/posterPath'] = model.movie.poster_path;
                fanoutObject['users/' + userId + '/ratings/' + model.movie.id + '/movieTitle'] = model.movie.title;

                this.get('firebaseUtil').update(fanoutObject).then(function () {}).catch(function (error) {
                    alert(error);
                });
            },
            removeRating: function removeRating(model) {
                var movie = model.movie;
                var fanoutObject = {};
                var userId = this.get('session.currentUser.uid');
                fanoutObject['users/' + userId + '/ratings/' + movie.id] = null;

                this.get('firebaseUtil').update(fanoutObject).then(function () {}).catch(function (error) {
                    alert(error);
                });
            },
            wannaSee: function wannaSee(model) {
                var movie = model.movie;
                var fanoutObject = {};
                var userId = this.get('session.currentUser.uid');

                fanoutObject['users/' + userId + '/wannaSees/' + movie.id + '/posterPath'] = movie.poster_path;
                fanoutObject['users/' + userId + '/wannaSees/' + movie.id + '/movieTitle'] = movie.title;

                this.get('firebaseUtil').update(fanoutObject).then(function () {
                    _ember.default.set(model, 'wannaSee', true);
                }).catch(function (error) {
                    alert(error);
                });
            },
            dontWannaSee: function dontWannaSee(model) {
                var movie = model.movie;
                var fanoutObject = {};
                var userId = this.get('session.currentUser.uid');
                fanoutObject['users/' + userId + '/wannaSees/' + movie.id] = null;

                this.get('firebaseUtil').update(fanoutObject).then(function () {
                    _ember.default.set(model, 'wannaSee', false);
                }).catch(function (error) {
                    alert(error);
                });
            }
        }
    });
});