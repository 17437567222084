define('movie-rating-ember/routes/search', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Route.extend({
        queryParams: {
            value: {
                refreshModel: true
            }
        },

        titleToken: function titleToken(model) {
            return 'Search - ' + model.query;
        },
        model: function model(_ref) {
            var value = _ref.value;

            var query = value;
            var search = _ember.default.$.getJSON('https://api.themoviedb.org/3/search/movie?query=' + value + '&api_key=0910db5745f86638474ffefa5d3ba687');

            return _ember.default.RSVP.hash({
                query: query,
                search: search
            });
        }
    });
});