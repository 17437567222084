define("movie-rating-ember/controllers/signup", ["exports", "ember"], function (exports, _ember) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _ember.default.Controller.extend({
        firebaseApp: _ember.default.inject.service(),
        actions: {
            signUp: function signUp() {
                var email = document.getElementById("email").value;
                var username = document.getElementById("username").value;
                var password = document.getElementById("password").value;
                var password2 = document.getElementById("password2").value;

                var validLogin = this.passIsValid(password, password2) && this.usernameIsValid(username) && this.emailIsValid(email);

                if (validLogin) {
                    var self = this;
                    this.get('firebaseApp').auth().createUserWithEmailAndPassword(email, password).then(function (result) {
                        var newUser = self.store.createRecord('user', {
                            id: result.uid,
                            email: email,
                            username: username
                        });
                        newUser.save();

                        self.get('session').open('firebase', {
                            provider: 'password',
                            email: email,
                            password: password
                        }).then(function () {
                            self.transitionToRoute('application');
                        }, function () {
                            alert("Failure");
                        });
                    }).catch(function (error) {
                        alert(error);
                    });
                }
            }
        },
        passIsValid: function passIsValid(password, password2) {
            return password == password2 && password != "";
        },
        usernameIsValid: function usernameIsValid(username) {
            return !this.usernameTaken(username);
        },
        usernameTaken: function usernameTaken(username) {
            if (username == "") {
                alert("Please enter a username");
                return true;
            }
            this.store.query('user', {
                orderBy: 'username',
                equalTo: username
            }).then(function (records) {
                if (records.get('length') > 0) {
                    alert("username already taken");
                    return true;
                }
                return false;
            });
        },
        emailIsValid: function emailIsValid(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (re.test(email) == false) {
                alert("not a valid email address");
                return false;
            }
            return !this.emailTaken(email);
        },
        emailTaken: function emailTaken(email) {
            if (email == "") {
                return true;
            }

            this.store.query('user', {
                orderBy: 'email',
                equalTo: email
            }).then(function (records) {
                if (records.get('length') > 0) {
                    alert("email is taken");
                    return true;
                }
                return false;
            });
        }
    });
});