define('movie-rating-ember/router', ['exports', 'ember', 'movie-rating-ember/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = _ember.default.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    headData: _ember.default.inject.service(),

    setTitle: function setTitle(title) {
      this.get('headData').set('title', title);
    }
  });

  Router.map(function () {
    this.route('movie', { path: '/movie/:movie_id' });
    this.route('login');
    this.route('user', { path: '/user/:username' });
    this.route('show'); //, {path: '/show/:show_id'});
    this.route('signup');
    this.route('search');
    this.route('privacy-policy');
    this.route('about');
    this.route('donate');
  });

  exports.default = Router;
});